import React from 'react'

const Video = ({ videoSrcURL, videoTitle, className, description, ...props }) => (
  <div className="videoContainer">
    <video
      className={`video ${className}`}
      src={videoSrcURL}
      alt={description}
      muted
      playsInline
      autoPlay="autoplay"
      loop
    ></video>
  </div>
)

export default Video

import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { TimelineLite } from 'gsap/all'
import SplitText from 'gsap/SplitText'

import { useInView } from 'react-intersection-observer'

const TextEffect = ({ lineHeight, text, delay }) => {
  let refContainer = useRef(null)

  const TextContainer = styled.div`
    color: white;
    line-height: ${lineHeight || 1.4};

    .lineContainer {
      position: relative;
      display: inline-block;
      overflow: hidden;
    }

    .lines {
      transform: translateY(100%);
      opacity: 0;
    }
  `

  const [refView, inView] = useInView({
    rootMargin: '50px 0px',
    triggerOnce: true,
  })

  const [resize, setResize] = useState(false)

  const handleResize = () => {
    setResize(!resize)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const wrap = (el, wrapper) => {
    el.parentNode.insertBefore(wrapper, el)
    wrapper.appendChild(el)
    wrapper.setAttribute('class', 'lineContainer')
  }

  useEffect(() => {
    if (inView) {
      const tl = new TimelineLite()

      const myElement = new SplitText(refContainer, {
        type: 'lines',
        linesClass: 'lines lines++',
      })

      const lines = myElement.lines

      for (const line of lines) {
        wrap(line, document.createElement('span'))
      }

      tl.fromTo(
        lines,
        {
          opacity: 0,
          y: '100%',
        },
        { opacity: 1, y: 0, duration: 0.8, stagger: 0.15, delay: delay || 0 },
        '+=0'
      )
    }
  }, [inView])

  return (
    <div ref={refView}>
      <TextContainer
        ref={div => (refContainer = div)}
        className={`textEffectContainer`}
      >
        {text}
      </TextContainer>
    </div>
  )
}

export default TextEffect
